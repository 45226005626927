import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AdminRoleProvider from '@/wrappers/AdminRoleProvider.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: AdminRoleProvider,
        children: [{
            path: '',
            name: 'home',
            component: () => import('@/views/Games/GamesList.vue'),
        },
        {
            path: 'games/new',
            name: 'game-create',
            component: () => import('@/views/Game/GamePage.vue'),
        },
        {
            path: 'games/:param',
            name: 'games',
            component: () => import('@/views/Game/GamePage.vue'),
        }],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
