import { defineStore } from 'pinia'
import request from '@/utils/request'

export function userAllowedToUseService(): Promise<boolean> {
    return request<boolean>('/authorized')
        .then(({ errors }) => !errors.length)
}

export const useUserStore = defineStore('user', {
    state: () => ({
        authorized: false,
    }),
    getters: {
        isModerator: (state) => state.authorized,
    },
    actions: {
        checkUserLoggedIn() {
            return userAllowedToUseService().then((authorized) => {
                this.authorized = authorized
            })
        },
    },
})
